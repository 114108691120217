<template>
<main class="content">
  <div class="container mb-3">
    <div class="row">
      <div class="col-md-8">
        <h3>Individueller Zaun-Konfigurator</h3>
        <small>mit Sofortpreis fur komplette Zaunanlagen.</small>
        <p><span class="badge badge-danger live">Live Vorschau</span></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <LivePreview />
      </div>
      <div class="col-md-3">
        <ControlBox />
      </div>
    </div>
  </div>
  <StepNavigation />
  <Options />
  <Construction :option-one="optionsPfosten" :loading="cLoading"/>
</main>
</template>
<script>
import StepNavigation from './common/StepNavigation'
import Options from './common/Options'
import LivePreview from './common/LivePreview.vue'
import Construction from '@/pages/system/common/Construction.vue'
import axios from 'axios'
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useMeta } from 'vue-meta'
import { computed } from '@vue/reactivity'
import ControlBox from '@/pages/system/common/ControlBox.vue'
export default {
  components: {
    ControlBox,
    Construction,
    StepNavigation,
    Options,
    LivePreview
  },
  setup () {
    const store = useStore()
    useMeta({
      title: 'Loewe-Zaun.de - System'
    })

    // computed
    const cLoading = computed(() => store.getters['system/cLoading'])
    const optionsPfosten = computed(() => {
      const pfosten = store.getters['system/getOptionsPfosten']
      if (typeof pfosten !== 'undefined') {
        const option = pfosten.options.find(s => s.selected)
        if (option.name.toLowerCase() === 'eigene pfosten') {
          return false
        }
      }
      return true
    })
    // methods
    async function initSystem () {
      const apiKey = btoa(process.env.VUE_APP_API_KEY)
      const apiUrl = process.env.VUE_APP_API_URL
      await axios.post(apiUrl + 'builder', { re: apiKey }).then(resp => {
        if (resp.data && resp.data.definition) {
          store.dispatch('system/setSystem', resp.data.definition)
        }
      })
    }

    // hooks
    onMounted(() => {
      initSystem()
    })
    return {
      cLoading,
      optionsPfosten
    }
  }
}
</script>
