<script setup>
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import { ref } from 'vue'

// eslint-disable-next-line no-undef
defineProps({
  optionOne: {
    type: Boolean
  },
  loading: {
    type: Boolean
  }
})

// use
const store = useStore()

// refs
const blocked = ref(true)
const animate = ref(false)
// computed
const systems = computed(() => store.getters['system/system'])
const getMailboxName = computed(() => store.getters['system/mailboxName'])
console.log(getMailboxName.value)
const availableWidth = computed(() => store.getters['system/availableWidth'])
const configMeter = computed(() => store.getters['system/getMetersFromConfigs'])

const mainMeter = computed(() => {
  return configMeter.value.find(m => m.name === 'main')
})
const subOneMeter = computed(() => {
  return configMeter.value.find(m => m.name === 'sub_1')
})
const subSecMeter = computed(() => {
  return configMeter.value.find(m => m.name === 'sub_2')
})
</script>

<template>
  <div class="mb-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h3>Aktuelles aussehen Ihres Zauns: </h3>
          <p class="text-info">Bestimmen Sie die Anordnung Ihrer Tore <tooltip :tooltip="{ text: 'Die Reihenfolge der Zaun-und Tor Elemente gilt nur als Beispiel. Nach den Autrag werden diese in der Technische Zeichnung genauer definiert.'}" style="display:inline-block"><span class="fa fa-info-circle text-danger"></span></tooltip></p>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="constructor" :class="{loading: !loading}">
            <span v-if="!loading">Loading</span>
<!--            <loading-spinner v-if="!loading"></loading-spinner>-->
            <!--<div
            class="image-wrapper-cons mx-auto"
            v-if="loading"
            :animate="animate && blocked">-->
            <div
              class="image-wrapper-cons mx-auto"
              v-if="loading"
              :animate="animate && blocked"
              :options="{ group: 'box'}"
            >
              <img src="@/assets/img/post.png" style="z-index:5;" :class="{ 'pfosten': !optionOne }">
              <img src="@/assets/img/hanger.png">
              <!-- gigabox -->
              <div id="basic" v-if="getMailboxName === 'Briefkastensäule' || getMailboxName === 'Briefkastensäule 2 Wohneinheiten'">
                <img src="@/assets/img/hanger.png">
                <img src="@/assets/img/gigabox.png">
                <img src="@/assets/img/hanger.png">
                <img src="@/assets/img/post.png" style="z-index:5;" :class="{ 'pfosten': !optionOne }">
                <img src="@/assets/img/hanger.png" >
                <span id="meter" class="mrg-sub-3"><strong>0.45m</strong></span>
              </div>
              <!-- /gigabox -->
              <!-- proften -->
              <div id="basic" v-if="systems.config_sub_1.show">
                <img src="@/assets/img/hanger.png">
                <img src="@/assets/img/proften.png" style="width:70px">
                <img src="@/assets/img/hanger.png">
                <img src="@/assets/img/post.png" style="z-index:5;" :class="{ 'pfosten': !optionOne }">
                <img src="@/assets/img/hanger.png">
                <span id="meter"  class="mrg-sub-1"><strong>{{ subOneMeter.value }}m</strong></span>
              </div>
              <!-- /proften -->
              <div id="basic">
                <!-- section -->
                <!-- Box1 -->
                <span style="position:relative">
                  <img v-if="(getMailboxName === 'BOX1' || getMailboxName === 'BOX2') && systems.config_sub_1.show" src="@/assets/img/box2.png" style="position: absolute;z-index:7;left:20px">
                  <img v-if="(getMailboxName === 'BOX1' || getMailboxName === 'BOX2') && !systems.config_sub_1.show" src="@/assets/img/box2.png" style="position: absolute;z-index:7;left:20px">
                  <img v-if="!systems.config_sub_1.show || !systems.config_sub_2.show" src="@/assets/img/basic.png" style="width:166px;">
                </span>
                <!-- /Box1 -->
                <img src="@/assets/img/hanger.png" v-if="!systems.config_sub_1.show || !systems.config_sub_2.show">
                <img src="@/assets/img/post.png" style="z-index:5;" :class="{ 'pfosten': !optionOne }" v-if="!systems.config_sub_1.show || !systems.config_sub_2.show">
                <img src="@/assets/img/hanger.png" v-if="!systems.config_sub_1.show || !systems.config_sub_2.show">
                <img src="@/assets/img/basic.png" style="width:166px;">

                <!-- /section -->
                <img src="@/assets/img/hanger.png">
                <img src="@/assets/img/post.png" style="z-index:5;" :class="{ 'pfosten': !optionOne }">
                <img src="@/assets/img/hanger.png">
                <img src="@/assets/img/basic.png" style="width:166px;">
                <img src="@/assets/img/hanger.png">
                <img src="@/assets/img/post.png" style="z-index:5;" :class="{ 'pfosten': !optionOne }">
                <img src="@/assets/img/hanger.png">

                <img src="@/assets/img/basic.png" style="width:166px;">
                <img src="@/assets/img/hanger.png">
                <img src="@/assets/img/post.png" style="z-index:5;" :class="{ 'pfosten': !optionOne }">
                <img src="@/assets/img/hanger.png">
                <img src="@/assets/img/basic.png" style="width:166px;">

                <img src="@/assets/img/hanger.png" v-if="!systems.config_main.show">
                <img src="@/assets/img/post.png" style="z-index:5;" v-if="!systems.config_main.show" :class="{ 'pfosten': !optionOne }">
                <img src="@/assets/img/hanger.png" v-if="!systems.config_main.show">

                <img src="@/assets/img/basic.png" style="width:166px;" v-if="!systems.config_main.show">

                <img src="@/assets/img/hanger.png">
                <img src="@/assets/img/post.png" style="z-index:5;">
                <span id="meter">Zaun - <strong>{{ availableWidth }}m</strong></span>
              </div>

              <!-- schibetore -->
              <div id="basic" v-if="systems.config_main.show">
                <img src="@/assets/img/hanger.png" >
                <img src="@/assets/img/post.png" style="z-index:5" :class="{ 'pfosten': !optionOne }">
                <img
                  src="@/assets/img/shibetore.png"
                  style="width:300px;margin-left:-113px"
                  class="sortable">
                <img src="@/assets/img/hanger.png">
                <img src="@/assets/img/post.png" style="z-index:5;">
                <span id="meter" class="mrg-main">Schibetore - <strong>{{ mainMeter.value }}m</strong></span>
              </div>
              <!-- /schibetore -->
              <!-- Flügeltore -->
              <div id="basic" v-if="systems.config_sub_2.show">
                <!--<img src="/img/hanger.png" >
                <img src="/img/post.png" style="z-index:5;" :class="{ 'pfosten': !optionOne }">-->
                <img src="@/assets/img/hanger.png">
                <img src="@/assets/img/flugeltor.png" style="width:212px">
                <img src="@/assets/img/hanger.png">
                <img src="@/assets/img/post.png" style="z-index:5;">
                <span id="meter" class="mrg-sub-2">Flügeltore - <strong>{{ subSecMeter.value }}m</strong></span>
              </div>
              <!-- /Flügeltore -->
              <!--</div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.constructor,
.image-wrapper-cons {
  position: relative;
  display: flex;
  flex-flow: nowrap;
  justify-content: start;
  align-items:flex-start;

  img {
    height: 100px;
  }

  .pfosten {
    background: url('~@/assets/img/foundation.jpg') no-repeat;
    width: 25px;
    height: 100px;
    padding: 13px;
  }
}
@media(max-width: 1200px) {
  .image-wrapper-cons {
    width: 1140px;
  }
  .constructor {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

#basic {
  display: flex;
  position: relative;
}
#meter {
  position: absolute;
  bottom: -35px;
  border-bottom: 5px solid #dc3545;
  border-right: 5px solid #dc3545;
  border-left: 5px solid #dc3545;
  width: 100%;
  text-align: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.mrg-main {
  left: 9px;
}
.mrg-sub-1 {
  left: -9px;
}
.mrg-sub-2 {
  left: 9px;
}
.mrg-sub-3 {
  left: -10px;
}
</style>
