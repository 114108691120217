<script setup>

// import PriceBox from "@/pages/configurator/common/priceBox.vue";
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import InfoBox from '@/pages/system/common/InfoBox.vue'

const store = useStore()
const prices = computed(() => store.getters['builder/getTotalPrices'])

const discounted = computed(() => {
  if (prices.value && prices.value.discounted > 0) {
    return prices.value.discounted ? prices.discounted : 0
  }
  return 0
})
const price = computed(() => {
  if (prices.value && prices.value.price) {
    return prices.value.price
  }
  return 0
})
</script>

<template>
  <div class="card">
    <div class="card-body">
      <p class="control-box-text">Hier finden Sie Beispiele zu Ihrer Auswahl!</p>
      <InfoBox />
      <div class="card-body text-right">
        Aktueller Preis: <br>
        <span class="text-danger discounted" v-if="discounted > 0">{{ $filters.currency(discounted + ((montageStatus) ? montageCost : 0)) }}</span>
        <span class="text-price" :class="{'old-price': discounted > 0}">{{ $filters.currency(price + ((montageStatus) ? montageCost : 0)) }}</span>
        <p class="text-right"><small class="text-muted">inkl. 19% MwSt. & Versandkosten</small></p>
      </div>
    </div>
  </div>
</template>
