<template>
<div v-if="conf" :class="style">
  <img v-for="(img, index) in images" :src="$filters.image(img.path, imageDimension + 'x')" :key="index" />
</div>
</template>
<script>
import { computed } from '@vue/reactivity'
export default {
  props: {
    images: {
      required: true
    },
    config: {
      required: true
    },
    imageDimension: {
      required: true,
      default: 350
    },
    style: {
      required: true
    }
  },
  setup (props) {
    const conf = computed(() => {
      console.log(props.config)
      if (props.config === 'base' || props.config === 'main' || props.config === 'sub_1') {
        return true
      }
      if (props.conf === 'base') {
        return true
      }
      return false
    })
    return {
      conf
    }
  }
}
</script>
<style lang="scss" scoped>
    img {
        position: absolute;
        height: auto;
        width: auto;
    }
    .image-elements-sub_1 {
        z-index: 0;
        position: absolute;
        top: 43px;
        left: 138px;

        img {
          position: absolute;
        }
    }
    .image-elements-main {
        z-index: 1;
        position: absolute;
        top: 30px;
        left: 355px;

        }
    .image-elements-base {
        z-index: 1;
        position: absolute;
        top: 41px;
        left: -38px;

        img {
          position: absolute;
        }
    }
    .image-elements-add-base {
        z-index: 0;
        position: absolute;
        top: 60px;
        right: 244px;

        img {
          position: absolute;
        }
    }
</style>
